import { useCallback, useMemo } from "react"
import useAuth from "@/hooks/useAuth"
import API from "@/lib/api"
import { Masquerade } from "@/store/auth/authSlice"
import {
  getCheckpointsByRoleAndUserType,
  ProfileCheckpoints,
} from "@/components/ProfileOnboarding/utils"

const userTypeEndpoints = {
  artist: "/api/artist_profile/{slug}/",
  brand: "/api/brand_profile/{slug}/",
}

export default function useProfileCompletion() {
  const { masquerade, setMasquerade, user } = useAuth()

  const myMasqueradeUser = masquerade?.active_users?.find(
    ({ email }) => email === user?.email
  )
  const role = useMemo(() => {
    return myMasqueradeUser?.access_type
  }, [myMasqueradeUser?.access_type])

  const markAsCompleted = useCallback(
    async (checkpoint: ProfileCheckpoints) => {
      await API.modify<Masquerade>(
        "PUT",
        userTypeEndpoints[masquerade.type?.toLowerCase()].replace(
          "{slug}",
          masquerade.slug
        ),
        {
          metadata: {
            ...masquerade?.metadata,
            completionsDone: [
              ...(masquerade?.metadata?.completionsDone || []),
              checkpoint,
            ],
          },
        }
      )
      setMasquerade(masquerade.type, {
        ...masquerade,
        metadata: {
          ...masquerade?.metadata,
          completionsDone: [
            ...(masquerade?.metadata?.completionsDone || []),
            checkpoint,
          ],
        },
      } as any)
    },
    [masquerade, setMasquerade]
  )

  const checkPoints = useMemo(() => {
    return getCheckpointsByRoleAndUserType(
      masquerade?.type?.toLowerCase() as any,
      role?.toLowerCase() as any
    ).map((checkpoint) => {
      return {
        ...checkpoint,
        link: checkpoint.link
          ?.replace("{{type}}", masquerade?.type?.toLowerCase() as any)
          ?.replace("{{slug}}", masquerade?.slug),
      }
    })
  }, [masquerade?.slug, masquerade?.type, role])

  const completionsDone = useMemo(() => {
    return [...(masquerade?.metadata?.completionsDone || [])]
  }, [masquerade?.metadata?.completionsDone])

  return useMemo(() => {
    return {
      checkPoints,
      markAsCompleted,
      completionsDone,
    }
  }, [checkPoints, completionsDone, markAsCompleted])
}
