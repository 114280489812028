import { SVGProps } from "react"

export const FinalizeProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.9987 10.3333H4.9987C4.06832 10.3333 3.60313 10.3333 3.2246 10.4482C2.37233 10.7067 1.70539 11.3736 1.44686 12.2259C1.33203 12.6044 1.33203 13.0696 1.33203 14M9.66536 5C9.66536 6.65685 8.32222 8 6.66536 8C5.00851 8 3.66536 6.65685 3.66536 5C3.66536 3.34315 5.00851 2 6.66536 2C8.32222 2 9.66536 3.34315 9.66536 5ZM7.33203 14L9.3996 13.4093C9.49861 13.381 9.54812 13.3668 9.59429 13.3456C9.63529 13.3268 9.67427 13.3039 9.71064 13.2772C9.75159 13.2471 9.788 13.2107 9.86081 13.1379L14.1654 8.83336C14.6256 8.37311 14.6256 7.62689 14.1654 7.16665C13.7051 6.70642 12.9589 6.70642 12.4987 7.16666L8.19414 11.4712C8.12133 11.544 8.08492 11.5804 8.05486 11.6214C8.02816 11.6578 8.00522 11.6967 7.9864 11.7377C7.9652 11.7839 7.95105 11.8334 7.92276 11.9324L7.33203 14Z"
      stroke="#262726"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FinalizeProfileIcon
