import PublishProfileIcon from "@/icons/PublishProfileIcon"
import UserAddIcon from "@/icons/UserAdd"
import CompleteQuestionIcon from "@/icons/CompleteQuotestion"
import FinalizeProfileIcon from "@/icons/FinalizeProfileIcon"

// eslint-disable-next-line import/prefer-default-export
export enum ProfileCheckpoints {
  REVIEW_PROFILE = "REVIEW_PROFILE",
  COMPLETE_MATCHING_CRITERIA = "COMPLETE_MATCHING_CRITERIA",
  COMPLETE_PREFERENCE_QUESTIONNAIRE = "COMPLETE_PREFERENCE_QUESTIONNAIRE",
  INVITE_MANAGER = "INVITE_MANAGER",
  INVITE_OWNER = "INVITE_OWNER",
  PUBLISH_PROFILE = "PUBLISH_PROFILE",
}

export const ALL_CHECKPOINTS = [
  {
    key: ProfileCheckpoints.REVIEW_PROFILE,
    title: `Review {{profileName}}’s profile`,
    description: `It allows other teams to learn about {{profileName}} and their projects.`,
    Icon: FinalizeProfileIcon,
    brandRoles: ["admin", "owner"],
    artistRoles: ["lead", "admin", "owner"],
    link: "/{{type}}/{{slug}}/edit",
  },
  {
    key: ProfileCheckpoints.COMPLETE_MATCHING_CRITERIA,
    title: "Complete matching criteria questions",
    description:
      "We use your answers to match you to the best collaborations and collaborators.",
    Icon: CompleteQuestionIcon,
    brandRoles: ["admin"],
    artistRoles: ["lead", "admin"],
    link: "/{{type}}/{{slug}}/preference/edit",
  },
  {
    key: ProfileCheckpoints.COMPLETE_PREFERENCE_QUESTIONNAIRE,
    title: "Complete the preference questionnaire",
    description:
      "We use your answers to match you to the best collaborations and collaborators.",
    Icon: CompleteQuestionIcon,
    brandRoles: ["owner"],
    artistRoles: ["owner"],
    link: "/{{type}}/{{slug}}/preference/edit",
  },
  {
    key: ProfileCheckpoints.INVITE_MANAGER,
    title: "Invite the artist’s manager (admin)",
    description: "Invite lorem ipsum dolor",
    Icon: UserAddIcon,
    brandRoles: [],
    artistRoles: ["lead"],
    link: "/dashboard/admin",
  },
  {
    key: ProfileCheckpoints.INVITE_OWNER,
    title: "Invite the owner to claim this profile",
    description: "The owner is usually someone that works within the company.",
    Icon: UserAddIcon,
    brandRoles: ["admin"],
    artistRoles: ["admin"],
    link: "/dashboard/admin",
  },
  {
    key: ProfileCheckpoints.PUBLISH_PROFILE,
    title: "Publish your profile once ready",
    description:
      "Your profile page and listed projects will be live and discoverable to other teams on Lumina",
    Icon: PublishProfileIcon,
    brandRoles: ["admin", "owner"],
    artistRoles: ["lead", "admin", "owner"],
    link: "/dashboard/manage-visibility",
  },
]

export const CHECKPOINT_SIDEBAR_ITEM_MAP = {
  [ProfileCheckpoints.REVIEW_PROFILE]: "Manage Profile",
  [ProfileCheckpoints.COMPLETE_MATCHING_CRITERIA]: "Manage Preferences",
  [ProfileCheckpoints.COMPLETE_PREFERENCE_QUESTIONNAIRE]: "Manage Preferences",
  [ProfileCheckpoints.INVITE_OWNER]: "{{type}} Team",
  [ProfileCheckpoints.INVITE_MANAGER]: "{{type}} Team",
  [ProfileCheckpoints.PUBLISH_PROFILE]: "Manage Visibility",
}

export function getCheckpointsByRoleAndUserType(
  userType: "brand" | "artist",
  role: "admin" | "owner" | "lead"
) {
  return ALL_CHECKPOINTS.filter((checkpoint) => {
    if (userType === "brand") {
      return checkpoint.brandRoles.includes(role)
    }
    return checkpoint.artistRoles.includes(role)
  })
}
