import { SVGProps } from "react"

export const PublishProfileIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.33203 8C1.33203 5.79086 3.12289 4 5.33203 4H10.6654C12.8745 4 14.6654 5.79086 14.6654 8C14.6654 10.2091 12.8745 12 10.6654 12H5.33203C3.12289 12 1.33203 10.2091 1.33203 8Z"
      stroke="#262726"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.33203 9.66667C6.25251 9.66667 6.9987 8.92047 6.9987 8C6.9987 7.07953 6.25251 6.33333 5.33203 6.33333C4.41156 6.33333 3.66536 7.07953 3.66536 8C3.66536 8.92047 4.41156 9.66667 5.33203 9.66667Z"
      stroke="#262726"
      strokeWidth="1.33"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PublishProfileIcon
